/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import LocationCard from "../settings/myProfile/LocationCard";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import XSelect from "../../component/form/XSelect";
import { userStatusOptions } from "../../utils/options";
import { toaster } from "../../utils/toast";
import useSidebar from "../../hooks/useSidebar";
import XButton from "../../component/button/XButton";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("First Name is required").matches(/^[A-Za-z]+$/, "First Name must contain only alphabets"),
  lastName: Yup.string().required("Last Name is required").matches(/^[A-Za-z]+$/, "Last Name must contain only alphabets"),
  organizationName: Yup.string().required("Organization name is required"),
  // businessLogo: Yup.string().required("Logo is required"),
  websiteURL: Yup.string().required("URL is required").url("Enter a valid website URL"),
  phoneNumber: Yup.string()
  .matches(/^\d{10}$/,"Phone number must be exactly 10 digits")
  .required("Enter your phone number"),
  status: Yup.string().required("Status is required"),
  locations: Yup.array()
    .of(
      Yup.object().shape({
        address: Yup.string().required("Address is required"),
        zipcode: Yup.string()
          .required("Zipcode is required")
          .matches(/^\d{5}$/, "Zipcode must be a number of 5 digits"),
        country_id: Yup.string().required("country is required"),
        state_id: Yup.string().required("state is required"),
        city: Yup.string().required("city is required"),
      })
    )
    .min(1, "At least one location is required"),
});

const AddOrganization = () => {
  const navigate = useNavigate();
  const { postData, isLoading } = useAxios();
  const location = useLocation();
  const { selectedOrgData } = location.state || {};
  const { onShowSidebar } = useSidebar();
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      businessLogo: "",
      websiteURL: "",
      phoneNumber: "",
      totalCountPerCompany: "",
      contactsMinusUnsubscribes: "",
      status: "",
      locations: [
        {
          country_id: "",
          state_id: "",
          city: "",
          address: "",
          zipcode: "",
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let preLogo = selectedOrgData?.filename;
      const payload = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        business_name: values.organizationName,
        previousLogo: preLogo,
        website_url: values.websiteURL,
        phone_number: values.phoneNumber,
        is_active: values.status === "Active" ? true : false,
        locations: values.locations,
      };
      let response
      try {
        if (selectedOrgData === undefined) {
          const formData = new FormData();
          formData.append("body", JSON.stringify(payload));
          formData.append("logo", values?.businessLogo);
          response = await postData(API_ENDPOINTS.ADD_UPDATE_ORGANIZATION, formData);
        } else {
          const updatedPayload = { ...payload, orgId: selectedOrgData.userId };
          const formData = new FormData();
          formData.append("body", JSON.stringify(updatedPayload));
          formData.append("logo", values?.businessLogo);
          response = await postData(API_ENDPOINTS.ADD_UPDATE_ORGANIZATION, formData);
        }
        const {success, message} = response;
        if (success) {
          navigate("/Admin/Organizations");
          formik.resetForm();
        }
        toaster({ message, success });
      } catch (error) {
        toaster({ success: false, message: "Something went wrong" });
      }
    },
  });

  useEffect(() => {
    if (selectedOrgData) {
      formik.setValues({
        ...formik.values,
        email: selectedOrgData.email,
        firstName: selectedOrgData.first_name,
        lastName: selectedOrgData.last_name,
        organizationName: selectedOrgData.business_name,
        businessLogo: selectedOrgData.filename,
        websiteURL: selectedOrgData.website_url,
        phoneNumber: selectedOrgData.phone_number,
        status: selectedOrgData.is_active ? "Active" : "Deactive",
        locations: selectedOrgData.locations,
      });
    }
  }, [selectedOrgData, formik.setValues]);

  const addLocationHandler = () => {
    const blankLocationObject = [
      {
        country_id: "",
        state_id: "",
        city: "",
        address: "",
        zipcode: "",
      },
    ];
    formik.setFieldValue("locations", [
      ...formik.values.locations,
      ...blankLocationObject,
    ]);
  };

  const removeLocationHandler = (index: number) => {
    const newLocations = [...formik.values.locations];
    if (newLocations.length > 1) {
      newLocations.splice(index, 1);
      formik.setFieldValue("locations", newLocations);
    }
  };

  const handleImageChange = (selectedImage: any) => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  return (
    <div>
      <div className="main-title ">
        <h2>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          <p><span onClick={() => navigate("/Admin/Organizations")}><FontAwesomeIcon icon={faPlay} />{selectedOrgData ? "Edit Organization" : "Add Organization"}</span></p>
        </h2>
      </div>
      <div className="add-contact">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col xl={4} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter"
                  {...formik.getFieldProps("email")}
                />
                <Form.Text className="text-danger error-text">
                  {formik.touched.email && formik.errors.email}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter"
                  {...formik.getFieldProps("firstName")}
                />
                <Form.Text className="text-danger error-text">
                  {formik.touched.firstName && formik.errors.firstName}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter"
                  {...formik.getFieldProps("lastName")}
                />
                <Form.Text className="text-danger error-text">
                  {formik.touched.lastName && formik.errors.lastName}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter"
                  {...formik.getFieldProps("organizationName")}
                />
                <Form.Text className="text-danger error-text">
                  {formik.touched.organizationName &&
                    formik.errors.organizationName}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <Form.Group
                controlId="formBasicEmail"
                className="position-relative form-group"
              >
                <Form.Label>Upload Business Logo</Form.Label>
                <div className='position-relative'>
                  <Form.Control
                    type="file"
                    name="businessLogo"
                    accept='image/*'
                    onChange={(e) => {
                      const file = (e.currentTarget as HTMLInputElement).files?.[0];
                      handleImageChange(file);
                      formik.setFieldValue("businessLogo", file);
                    }}
                  />
                  {previewImage ? (
                      <img className='upload-image' alt="bussiness-Logo-Preview" height={50} width={50} src={previewImage} />
                    ) : (
                      <img className='upload-image' alt='bussiness-Logo' height={50} width={50} src={selectedOrgData?.logoUrl} />
                    )}
                  </div>
                {/* <FontAwesomeIcon icon={faUpload} className='upload-icon' /> */}
                <Form.Text className="text-danger error-text">
                  {formik.touched.businessLogo && formik.errors.businessLogo}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Website URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="https://www.website-url.com"
                  {...formik.getFieldProps("websiteURL")}
                />
                <Form.Text className="text-danger error-text">
                  {formik.touched.websiteURL && formik.errors.websiteURL}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter"
                  {...formik.getFieldProps("phoneNumber")}
                />
                <Form.Text className="text-danger error-text">
                  {formik.touched.phoneNumber && formik.errors.phoneNumber}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <XSelect
                  name={`status`}
                  label="Status"
                  options={userStatusOptions}
                  formik={formik}
                />
              </Form.Group>
            </Col>
          </Row>
          <p className="text-end add-link" >
            <span className='btn-add' onClick={addLocationHandler}><FontAwesomeIcon icon={faPlus} /> <span>Add Location</span></span>
          </p>
          {formik.values.locations.map((v: any, index: number) => (
            <LocationCard
              key={index}
              index={index}
              locationData={v}
              formik={formik}
              removeLocationHandler={removeLocationHandler}
            />
          ))}
          {formik?.errors?.hasOwnProperty("locations") && (
            <span style={{ color: "red" }}>
              All fields are required
            </span>
          )}
          <div className="btns">
            <div className="d-flex justify-content-between align-items-center">
              <Button
                variant="primary"
                className="btn-theme btn-border"
                onClick={() => navigate("/Admin/Organizations")}
              >
                <FontAwesomeIcon icon={faPlay} />
                Back
              </Button>
              <XButton
                variant="primary"
                type="submit"
                buttonText={<>Save Changes<FontAwesomeIcon icon={faPlay} /></>}
                loading={isLoading}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddOrganization;
